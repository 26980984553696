import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/onepass'
  },
  {
    path: '/onepass',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta:{
      // 页面标题title
      title: '智慧云菜市一码通'
    }
  },
  {
    path: '/HealthSafetyImg',
    name: 'HealthSafetyImg',
    component: () => import(/* webpackChunkName: "about" */ '../views/HealthSafetyImg.vue'),
    meta:{
      // 页面标题title
      title: '健康证'
    }
  },
  {
    path: '/BusinessLicenseImg',
    name: 'BusinessLicenseImg',
    component: () => import(/* webpackChunkName: "about" */ '../views/BusinessLicenseImg.vue'),
    meta:{
      // 页面标题title
      title: '营业执照'
    }
  },
  {
    path: '/FoodSafetyImg',
    name: 'FoodSafetyImg',
    component: () => import(/* webpackChunkName: "about" */ '../views/FoodSafetyImg.vue'),
    meta:{
      // 页面标题title
      title: '食品经营许可证'
    }
  },
  {
    path: '/traceabilityDetail',
    name: 'TraceabilityDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/TraceabilityDetail.vue'),
    meta:{
      // 页面标题title
      title: '溯源公示'
    }
  },
  {
    path: '/KitchenGarbage',
    name: 'KitchenGarbage',
    component: () => import(/* webpackChunkName: "about" */ '../views/KitchenGarbage.vue'),
    meta:{
      // 页面标题title
      title: '厨余垃圾记录列表'
    }
  },
  {
    path: '/MorningInspection',
    name: 'MorningInspection',
    component: () => import(/* webpackChunkName: "about" */ '../views/MorningInspection.vue'),
    meta:{
      // 页面标题title
      title: '晨检记录列表'
    }
  },
  {
    path: '/CutleryDisinfection',
    name: 'CutleryDisinfection',
    component: () => import(/* webpackChunkName: "about" */ '../views/CutleryDisinfection.vue'),
    meta:{
      // 页面标题title
      title: '餐具消毒记录列表'
    }
  },
  {
    path: '/certificate',
    name: 'Certificate',
    component: () => import(/* webpackChunkName: "about" */ '../views/Certificate.vue'),
    meta:{
      // 页面标题title
      title: '主体公示'
    }
  },
  {
    path: '/traceabilityList',
    name: 'TraceabilityList',
    component: () => import(/* webpackChunkName: "about" */ '../views/TraceabilityList.vue'),
    meta:{
      // 页面标题title
      title: '溯源公示'
    }
  },
  {
    path: '/monitoringPublicity',
    name: 'MonitoringPublicity',
    component: () => import(/* webpackChunkName: "about" */ '../views/MonitoringPublicity.vue'),
    meta:{
      // 页面标题title
      title: '检测公示'
    }
  },
  {
    path: '/notYetOpen',
    name: 'NotYetOpen',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotYetOpen.vue'),
    meta:{
      // 页面标题title
      title: '暂未开放'
    }
  },
  {
    path: '/supervision',
    name: 'Supervision',
    component: () => import(/* webpackChunkName: "about" */ '../views/Supervision.vue'),
    meta:{
      // 页面标题title
      title: '监管公示'
    }
  },
  {
    path: '/popularScience',
    name: 'PopularScience',
    component: () => import(/* webpackChunkName: "about" */ '../views/PopularScience.vue'),
    meta:{
      // 页面标题title
      title: '科普宣传'
    }
  },
  {
    path: '/marketDynamics',
    name: 'MarketDynamics',
    component: () => import(/* webpackChunkName: "about" */ '../views/MarketDynamics.vue'),
    meta:{
      // 页面标题title
      title: '市场动态'
    }
  },
  {
    path: '/FoodSafetyHistory',
    name: 'FoodSafetyHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/FoodSafetyHistory.vue'),
    meta:{
      // 页面标题title
      title: '历史食品安全承诺书'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: '/onepass/',
  routes,
})

export default router
